import React from "react"
import CategoryWrapper from "../../components/CategoryWrapper"

function WomenShirtsPage() {
  return (
    <CategoryWrapper
      seoTitle="Skjorter"
      gender="dame"
      category="shirts"
    />
  )
}

export default WomenShirtsPage
